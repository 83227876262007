<template lang="html">
  <div class="m2 wrapper">
    <Tabs>
      <Tab title="<i class='fas fa-arrow-left'></i><span>Tab 1</span>">Tab 1</Tab>
      <Tab title="Tab 2" :isDisabled="true">Tab 2</Tab>
      <Tab title="Tab 3" :selected="true" >Tab 3</Tab>
      <Tab title="Tab 4">Tab 4</Tab>
    </Tabs>
  </div>
</template>

<script>
  import Tab from "@/components/Tabs/Tab.vue"
  import Tabs from "@/components/Tabs/Tabs.vue"

  export default {
    components: {
      Tab,
      Tabs
    },
  }
</script>
 